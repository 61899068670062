import { cloneDeep } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { API_BASE_URL } from '@constants/env';
import { LoggerEventNumber, LoggerConstantsNumber } from "@constants/logConfig";
import { postDataWithoutLog } from "./httpClient"

const WorkspaceName = 'CSP';

// Keep these interface same as those in UCM repo.
interface IErrorContext {
    IsUserImpacting: boolean;
    UserImpactedColumns?: string;
    Area: string;
    SubArea: string;
    UserActionId: string;
    UserActionName: string;
    WorkspaceName: string;
}

interface ILogEntry {
    ActivityId: string;
    LogType: number;
    LogMessage: IMessage;
}

interface IMessage {
    UserId: string;
    SessionId: string;
    PageUrl: string;
    AjaxUrl: string;
    Content: string;
    StartTime: string;
    EndTime: string;
    DataLoadMsec: number;
    ErrorContext?: IErrorContext;
}

class Message implements IMessage {
    UserId: string;
    SessionId: string;
    PageUrl: string;
    AjaxUrl: string;
    Content: string;
    StartTime: string;
    EndTime: string;
    DataLoadMsec: number;
    ErrorContext?: IErrorContext;

    constructor(message: string) {
        this.Content = message;
        this.UserId = '';
        this.SessionId = '';
        this.PageUrl = '';
        this.AjaxUrl = '';
        this.StartTime = '';
        this.EndTime = '';
        this.DataLoadMsec = 0;
    }

    public setUserId(userId: string): void {
        this.UserId = userId;
    }

    public setSessionId(sessionId: string): void {
        this.SessionId = sessionId;
    }

    public setPageUrl(url: string): void {
        this.PageUrl = url;
    }

    public setAjaxUrl(url: string): void {
        this.AjaxUrl = url;
    }

    public setContent(content: string): void {
        this.Content = content;
    }

    public setStartTimeEndTime(startTime: Date, endTime: Date): void {
        if (startTime) {
            this.StartTime = startTime.toISOString();
        } else {
            this.StartTime = '';
        }
        if (endTime) {
            this.EndTime = endTime.toISOString();
        } else {
            this.EndTime = '';
        }
        if (startTime && endTime) {
            this.DataLoadMsec = endTime.getTime() - startTime.getTime();
        } else {
            this.DataLoadMsec = 0;
        }
    }

    public setErrorContext(errorContext: IErrorContext): void {
        this.ErrorContext = errorContext;
    }
}

class LogEntry implements ILogEntry {
    ActivityId: string;
    LogType: LoggerEventNumber;
    LogMessage: IMessage;
    constructor(activityId: string, logType: number, message: IMessage) {
        this.ActivityId = activityId;
        this.LogType = logType;
        this.LogMessage = message;
    }
}

class Logger {
    private logEntriesBuffer: Array<ILogEntry>;
    private apiUrl: string;
    private userId: string;
    private sessionId: string;

    private LogDataSizeLimit: number;
    private logBufferSize: number;
    private logBufferTimeOut: number;
    private timeoutHandle: number;

    constructor() {
        this.LogDataSizeLimit = 4e6;
        this.logEntriesBuffer = [];
        this.userId = LoggerConstantsNumber.DefaultUserID.toString();
        this.sessionId = '';
        this.timeoutHandle = 0;
        this.logBufferTimeOut = LoggerConstantsNumber.LogBufferTimeOut;
        this.logBufferSize = LoggerConstantsNumber.LogBufferSize;
        this.apiUrl = `${API_BASE_URL}/api/v2/CSPData/CSPLog`;

        this.onWindowUnload();
    }

    public updateSessionId(sessionId: string): void {
        this.sessionId = sessionId;
    }

    public updateUser(userId: string): void {
        this.userId = userId;
    }

    public error(error: string, area?: string, subArea?: string, impactedColumns?: string): void {
        const logEntry = this.getErrorLogEntry(error, false, area, subArea, impactedColumns);
        this.sendToServer(logEntry);
    }

    public info(message: string): void {
        const msg = new Message(`WorkspaceName:${WorkspaceName}, Message: ${message}`);
        const logEntry = new LogEntry('', LoggerEventNumber.InfoEvent, msg);
        this.sendToBuffer(logEntry);
    }

    public perfInfo(methondName: string, startTime: Date, endTime: Date): void {
        const msg = new Message(`WorkspaceName:${WorkspaceName}, Performance: ${methondName}`);
        msg.setStartTimeEndTime(startTime, endTime);
        const logEntry = new LogEntry('', LoggerEventNumber.InfoEvent, msg);
        this.sendToBuffer(logEntry);
    }

    public userImpactError(error: string, area?: string, subArea?: string, impactedColumns?: string): void {
        const logEntry = this.getErrorLogEntry(error, true, area, subArea, impactedColumns);
        this.sendToServer(logEntry);
    }

    private fetchLogBufferClone(): Array<ILogEntry> {
        const buffer = cloneDeep(this.logEntriesBuffer);
        this.logEntriesBuffer = [];
        clearTimeout(this.timeoutHandle);
        return buffer;
    }

    private onWindowUnload() {
        window.onbeforeunload = () => {
            if (!this.logEntriesBuffer || this.logEntriesBuffer.length == 0) {
                return;
            }
            this.logEntriesBuffer.forEach((logEntry: ILogEntry) => {
                logEntry.LogMessage.Content = logEntry.LogMessage.Content + ", LoggingContext: Logger-beforeunload";
            });
            this.pushLogToServer(this.logEntriesBuffer);
        };
    }

    private getErrorLogEntry(error: string, isUserImpacting: boolean, area?: string, subArea?: string, impactedColumns?: string): ILogEntry {
        error = this.appendUniqueId(error);
        const message = new Message(error);
        const errorContext: IErrorContext = {
            IsUserImpacting: isUserImpacting,
            Area: typeof area == "undefined" ? '' : area,
            SubArea: typeof subArea == "undefined" ? '' : subArea,
            UserImpactedColumns: typeof impactedColumns == "undefined" ? '' : impactedColumns,
            UserActionId: '',
            UserActionName: '',
            WorkspaceName: WorkspaceName
        };

        message.setErrorContext(errorContext);

        return new LogEntry('', LoggerEventNumber.FailureEvent, message);
    }

    private appendUniqueId(error: string): string {
        return error + ", UniqueErrorId: " + uuidv4();
    }

    private sendToBuffer(logEntry: ILogEntry) {
        this.updateLogWithAdditionalInfo(logEntry);
        this.logEntriesBuffer.push(logEntry);
        this.onLogBufferUpdate();
    }

    private sendToServer(logEntry: ILogEntry) {
        this.updateLogWithAdditionalInfo(logEntry);
        this.pushLogToServer([logEntry]);
    }

    private onLogBufferUpdate() {
        if (this.logEntriesBuffer.length === 1) {
            setTimeout(() => this.pushLogToServer(this.fetchLogBufferClone()), this.logBufferTimeOut);
            return;
        }

        if (this.logEntriesBuffer.length >= this.logBufferSize) {
            this.pushLogToServer(this.fetchLogBufferClone());
        }

        return;
    }

    private pushLogToServer(logEntries: Array<ILogEntry>) {
        if ((logEntries && logEntries.length === 0)) {
            return;
        }

        const logEntriesWithSize = logEntries.map((entry) => ({
            size: JSON.stringify(entry).length,
            data: entry
        }));

        const badEntries = logEntriesWithSize.filter((entryWithSize) => entryWithSize.size > this.LogDataSizeLimit).map((entryWithSize) => entryWithSize.data);
        badEntries.forEach((entry) => this.pushLogToServerWithFailCallback(JSON.stringify([entry])));
        const goodEntriesWithSize = logEntriesWithSize.filter((entryWithSize) => entryWithSize.size <= this.LogDataSizeLimit);
        if (goodEntriesWithSize.length === 0) return;
        let accumulatedSize = 0;
        let start = 0;
        for (let i = 0; i < goodEntriesWithSize.length; i++) {
            accumulatedSize += goodEntriesWithSize[i].size;
            if (accumulatedSize > this.LogDataSizeLimit) {
                this.pushLogToServerWithFailCallback(JSON.stringify(goodEntriesWithSize.slice(start, i).map((entryWithSize) => entryWithSize.data)));
                accumulatedSize = goodEntriesWithSize[i].size;
                start = i;
            }
        }
        // deal with the last batch
        this.pushLogToServerWithFailCallback(JSON.stringify(goodEntriesWithSize.slice(start).map((entryWithSize) => entryWithSize.data)));
    }

    private pushLogToServerWithFailCallback(data: string, retryCount: number = LoggerConstantsNumber.Retries) {
        if (retryCount === 0) {
            return;
        }

        const promise = postDataWithoutLog(this.apiUrl, data);
        promise.catch((error) => {
            if (console && console.error) {
                console.error("PushLogToServerWoCheck error: " + error);
            }
            setTimeout(() =>
                this.pushLogToServerWithFailCallback(data, retryCount - 1),
                5000
            );
        });
    }

    private updateLogWithAdditionalInfo(logEntry: ILogEntry) {
        logEntry.LogMessage.PageUrl = window.location.href;
        logEntry.LogMessage.UserId = this.userId;
        logEntry.LogMessage.SessionId = this.sessionId;
        if (console && console.log) {
            console.log("Type:" + logEntry.LogType + ", message:" + logEntry.LogMessage.Content);
        }
        return logEntry;
    }
}

export const logger = new Logger();
